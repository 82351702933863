export class API_METHOD{
    static get = 'GET';
    static post = 'POST';
    static patch = 'PATCH';
    static put = 'PUT';
    static delete = 'DELETE';
}
export class API_HOST{
    //static host = "http://localhost:8080";
    // static host = "https://soliterata.com";
    static host = "https://preprod.soliterata.com";
    static baseURL = "/soliterata_license";
    static hostUrl = API_HOST.host + API_HOST.baseURL;
}

// QueryDetails Table link db.json file 
export class API_POSTS{
static host = "http://localhost:3000/posts"
}



export class API_TICKET{
    static createTicket = "/ticket/createTicket";
}

export class TICKET_FILTER{
    static getTicket = "/ticket";
    static filterTicket="/ticket/filterTicket";
    static openTicket="/ticket/openTicket";
    static conversation="/ticket/conversation";
    static escalate="/ticket/transferTicket";
    static acceptTicket="/ticket/updateTicket";

}

export class API{
    static account = "/account";
    static finance = "/finance"
    static authenticate = "/authenticate";
    static register = "/register";
    static initforgotPassword = "/initforgotPassword";
    static confirmForgotPassword = "/confirmForgotPassword";
    static verifyforgotPasswordCode = "/verifyforgotPasswordCode";
    static resentVerifyCode = "/resentVerifyCode";
    static licenses = "/license";
    static leads = "/leads";
    static convert = "/leads/convert";
    static confirm = "/payment/confirm";
    static payment = "/payment";
    static licensesSuperAdmin = "/user/superadmin";
    static card = "/card";
    static user = "/user";
    static licenseBulk = "/purchaseLicense";
    static licenseReassign = "/reassign"
    static changePassword = "/changePassword"
    static licenseRenew = "/renew"
    static superadmin = "/superadmin"
    static freeLicence = "/freeLicence"
    static revoke = "/revoke"
    static delete = "/delete"
    static pricing = "/pricing"
    static limitations = '/community/limitation'
    static superAdminCertificate = '/certificate'
    static userCertificate = '/certificate/user'
    static userDownloadCertificate = '/certificate/user/download'
    static superAdminQuestions = '/certificate/question'
    static superAdminQuestionTemplate = '/certificate/question/download'
}
export class Header{
static header = localStorage.getItem('super-key') !== null ? `Bearer ${localStorage.getItem('super-key')}` : `${null}`

}

export class LicenseAPI{
    static create = "/createLicenseKey"; //! DO NOT USE THIS API WILL BE REMOVED IN PROD.
    static update = "/updateLicense";
    
}

export class ProjectAPI {
    /*  POST - create 
        PUT - update 
        DELETE - remove
    */
    static create = "/project";
    static reName = "/rename";
}


export class EditorAPI {
    static create = "/";
    static update = "/";
}

export class StepDefinitionsAPI {
    static create = "/";
    static update = "/";
}

export class EnvironmentAPI {
    static getEnvironmentDetais = "/details"
}

export class ScriptEditorAPI{
    static getScriptContent = "/getScriptContent";
    static reName = "/rename";
}

export class PlayListAPI{
    static getScriptContent = "/playListScripts";
    static reName = "/rename";
}

/* Console enable/disable */
export class CONSOLE_MODE{
    static mode = 'production'
   //  static mode = 'development'
  }