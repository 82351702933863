import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Typography,
  Button,
  TextField,
  Tooltip,
  CircularProgress,
  TextareaAutosize,
  Slide,
  Popover,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
// import styles from "./mystyle.module.css";
import {
  IconDetails,
  IconReassign,
  IconRenew,
  IconRevoke,
} from "../../../constants/icons";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Select from "@mui/material/Select";
import {
  deleteLic,
  getLicenseList,
  licenseRe,
  licenseRenew,
  revokeLic,
} from "../../../services/license";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { getClientIntent } from "../../../services/card-controller";
import { StatusCodes } from "http-status-codes";
// import { ModuleType } from "../../constants/application";
import validator from "validator";
// import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import {
  BootstrapDialog,
  ButtonMaterial,
  text_col,
} from "../../../constants/constStyles";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { BootstrapDialogTitle } from "..";
import { useContext } from "react";
import { ApplicationContext } from "../../../context/applicationCtx";
import { useNavigate } from "react-router-dom";

export function TableRowWithData({
  license,
  index,
  // getCardsList_CTX,
  setLicenseAl_CTX,
  setOpenPaymentConfirm,
  setLicenseList_CTX,
}) {
  const { setActiveModule,
    setPaymentScroller } = useContext(ApplicationContext);
    const navigate = useNavigate()
  let validDate = new Date(license.validTill);
  let currentDate = new Date();
  console.log(validDate, currentDate, "dates");
  // States
  // const { getCardsList_CTX, setLicenseAl_CTX, setLicenseList_CTX } =
  //   useContext(ApplicationContext);
  const [getLoaderState, setLoaderState] = useState(false);
  const [error, setError] = useState(null);
  const [transition, setTransition] = useState(undefined);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [licenseId, setLicenseId] = useState();
  const [openCopy, setOpenCopy] = useState(false);
  const [openCopyHover, setOpenCopyHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);
  const [openRevoke, setOpenRevoke] = useState(false);
  const [openRevokeSnack, setOpenRevokeSnack] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSnack, setOpenDeleteSnack] = useState(false);

  // const [select setSelect] = useState();
  // const [setSelect] = useState();
  const [systemCode, setSystemCode] = useState("");
  const [frequencyRenew, seFrequencyRenew] = useState();
  const [renewSnack, setRenewSnack] = useState();
  const [manualRenew, setManualRenew] = useState("Online");
  const [allocationChange, setAllocationChange] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [getClientCodeRenew_CTX, setClientCodeRenew_CTX] = useState();
  const [allocateSnack, setallocateSnack] = useState();
  const [reassignSnack, setreassignSnack] = useState(false);
  // const [setAge] = useState("");
  const [openTooltip, setTooltip] = useState(false);
  let selectsFRenew = [{ frequency: "Annually" }, { frequency: "Monthly" }];
  const [anchorEl, setAnchorEl] = useState(null);
  const openDetail = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDetail = () => {
    setAnchorEl(null);
  };
  console.log(allocationChange);
  // Licence allocation value get function
  const handleChangeIn = (prop) => (e) => {
    switch (prop) {
      case "firstName":
        setAllocationChange({
          ...allocationChange,
          firstName: e.target.value.replace(/[^A-Za-z\s]/gi, ""),
        });
        break;
      case "lastName":
        setAllocationChange({
          ...allocationChange,
          lastName: e.target.value.replace(/[^A-Za-z]/gi, ""),
        });
        break;
      case "email":
        setAllocationChange({ ...allocationChange, email: e.target.value });
        break;
      case "phone":
        setAllocationChange({
          ...allocationChange,
          phone: e.target.value.replace(/[^0-9]/gi, ""),
        });
        break;
      default:
        break;
    }
  };
  const handleBlurIn = (prop) => (e) => {
    switch (prop) {
      case "firstName":
        setFirstName(e.target.value.replace(/[^A-Za-z\s]/gi, ""));
        break;
      case "lastName":
        setLastName(e.target.value.replace(/[^A-Za-z]/gi, ""));
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value.replace(/[^0-9]/gi, ""));
        break;
      default:
        break;
    }
  };

  // Stripe payment confimation for 3D card during renew licence
  if (getClientCodeRenew_CTX) {
    async function clientCode() {
      let response = await getClientIntent(getClientCodeRenew_CTX);
      // console.log(response,)
      if (response.status === StatusCodes.OK) {
        let reloadList = await getLicenseList();
        setLicenseList_CTX(reloadList.data.licenseList);
        setLicenseAl_CTX(reloadList.data);
        const setSnack = (Transition) => {
          setTransition(() => Transition);
          setRenewSnack(true);
        };
        setSnack();
        setTimeout(() => {
          setRenewSnack(false);
        }, 2000);
      }
    }
    clientCode();
  }

  // Licence allocation function for api request
  const verifyInputs = async function () {
    try {
      setLoaderState(true);

      let response = await licenseRe({
        email: email,
        firstName: firstName,
        lastName: lastName,
        // licenseId: licenseId.licenseNo,
        phoneNum: phone,
        systemInfoCode: systemCode,
        licenseNo: license.licenseNo,
      });
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
      }
      if (response.status === StatusCodes.OK) {
        setError("");
        handleClose();
        // setOpen(false)
        let reloadList = await getLicenseList();
        setLicenseList_CTX(reloadList.data.licenseList);
        setLicenseAl_CTX(reloadList.data);
        setLoaderState(false);

        const setSnack = (Transition) => {
          setTransition(() => Transition);
          setallocateSnack(true);
        };
        setSnack();
        setTimeout(() => {
          setallocateSnack(false);
        }, 2000);
        // setReloadModuleData(true);
      } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
        setError("fields cant be empty");
        setLoaderState(false);
      } else if (response.status === StatusCodes.BAD_REQUEST) {
        setError(`${response.data.message}`);
        setLoaderState(false);
      }
    } catch (err) {
      // console.log("ERR =>",err);
      setLoaderState(false);
      setOpen(false);
      if (err.data) {
      } else {
        if (err.errorMsg) {
          // setStepLicenseKey(err)
          console.log(err.errorMsg);
        } else {
          console.error("UNKNOWN ERROR", err);
        }
      }
    }
  };

  // Licence renew function for api request
  const verifyInputRenew = async function () {
    sessionStorage.setItem("paymentType", "renew");
    try {
      // console.log(select)
      setLoaderState(true);

      let response = await licenseRenew({
        manualRenew: manualRenew === "Online" ? false : true,
        licenseNo: licenseId.licenseNo,
        frequency: frequencyRenew,
      });
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (!response) {
        // alert('Something went wrong!')
        setError(`**Unable to connect to server!**`);
        setLoaderState(false);
      }
      if (response.status === StatusCodes.ACCEPTED) {
        if (!response.data.redirectUrl) {
          // setError(response.data.message);
          if (!localStorage.getItem("super-key")) {
            handleClose();
            setError(response.data.message);
            setOpenPaymentConfirm(true);
          }
        }
        setLoaderState(false);
        sessionStorage.setItem("redirect-url", response.data.redirectUrl);
        sessionStorage.setItem("id", response.data.paymentIntent);
        navigate("/licence/purchaseredirect");
        setOpenRenew(false);
        setError(response.data.message);
        const setSnack = (Transition) => {
          setTransition(() => Transition);
          setRenewSnack(true);
        };
        setSnack();
        setTimeout(() => {
          setRenewSnack(false);
          setError("");
        }, 2000);
      }
      if (response.status === StatusCodes.OK) {
        if (!response.data.redirectUrl) {
          // setError(response.data.message);
          if (!localStorage.getItem("super-key")) {
            handleClose();
            setError(response.data.message);
            setOpenPaymentConfirm(true);
          }
        }
        setLoaderState(false);
        setOpenRenew(false);
        setError(response.data.message);
        let reloadList = await getLicenseList();
        setLicenseList_CTX(reloadList.data.licenseList);
        setLicenseAl_CTX(reloadList.data);
        const setSnack = (Transition) => {
          setTransition(() => Transition);
          setRenewSnack(true);
        };
        setSnack();
        setTimeout(() => {
          setRenewSnack(false);
          setError("");
        }, 2000);
        // setAge("");
      } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
        setError("Fields cant be empty");
        setLoaderState(false);
      } else if (response.status === StatusCodes.FAILED_DEPENDENCY) {
        setOpenRenew(false);
        // setClientCode(response.data.clientCode)
        let code = response.data.clientCode;
        var stripe = window.Stripe(
          "pk_test_51Kg0AFFgTsFRsmEw2JUsD7CpPsU1Lhrsi70B2on0QqBQdcBQRHHrwTSdBWnZzInKjw1anOC354WNV6CezdKEs6e700NM9rdzQk"
        );
        let clientSecret = code;

        stripe.handleCardAction(clientSecret).then(async function (result) {
          // console.log(result.paymentIntent.id);
          if (result.paymentIntent.id) {
          }
          if (result.error) {
            let reloadList = await getLicenseList();
            setLicenseList_CTX(reloadList.data.licenseList);
            setLicenseAl_CTX(reloadList.data);
          }
          console.log(result);
          setClientCodeRenew_CTX(result.paymentIntent.id);
        });
        setLoaderState(false);
      }
    } catch (err) {
      // console.log("ERR =>",err);
      setLoaderState(false);
      setOpenRenew(false);
      if (err.data) {
        // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
      } else {
        if (err.errorMsg) {
          // setStepLicenseKey(err)
          console.log(err.errorMsg);
        } else {
          console.error("UNKNOWN ERROR", err);
        }
      }
    }
  };

  // popup/snack opening & closing section

  // Copy snack
  const handleCloseSnack = () => {
    setOpenCopy(false);
  };

  let finalLicense = `${license.licenseKey}`;

  const handleCopy = (Transition) => {
    setTransition(() => Transition);
    setOpenCopy(true);
    license.status === "Expired"
      ? navigator.clipboard.writeText("")
      : navigator.clipboard.writeText(license.licenseKey);
  };

  // Renew Popup with frequency value
  const handleOpenRenew = () => {
    setError("");
    setManualRenew("Online");
    setOpenRenew(true);
    setLicenseId(license);
    if (license.frequency === "Annually") {
      seFrequencyRenew("Annually");
      // setRenewPlan(800);
    }
    if (license.frequency === "Monthly") {
      seFrequencyRenew("Monthly");
      // setRenewPlan(80);
    }
  };

  // Frequency selection during select option
  function handleSelectFRenew(select) {
    seFrequencyRenew(select.frequency);

    if (
      select.licenseType === "Business" &&
      select.frequency === "Annually" &&
      select.desktopModule
    ) {
    }
  }

  const handleCloseRenew = () => {
    setError("");
    setOpenRenew(false);
    setManualRenew("Online");
    // setAge("");
  };

  // Allocate popup and renew popup as per the validity
  const handleClickOpen = () => {
    setLicenseId(license);
    // seFrequencyRenew(license.frequency)
    if (currentDate >= validDate) {
      setOpenRenew(true);
      if (license.frequency === "Annually") {
        seFrequencyRenew("Annually");
        // setRenewPlan(692);
      }
      if (license.frequency === "Monthly") {
        seFrequencyRenew("Monthly");
        // setRenewPlan(64);
      }
    } else {
      setOpen(true);
    }
  };

  const handleModule = (licenseNo) => {
    if(!localStorage.getItem('super-key')){

      setPaymentScroller(licenseNo)
      setActiveModule(1);
      localStorage.setItem("state", "1");
    }
      
  };

  const handleClose = () => {
    setOpen(false);
    setError("");
    setAllocationChange({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    })
    setFirstName("");
    setLastName("");
    setEmail("");
    setSystemCode("");
    setPhone("");
  };
  // const handleSelect = (selectCard) => {
  //   // console.log(selectCard.cardId)
  //   setSelect(selectCard.cardId);
  // };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  const handleTextarea = (e) => {
    setSystemCode(e.target.value);
  };

  const revokeLicense = async () => {
    setLoaderState(true);
    try {
      let response = await revokeLic({
        licenseNo: licenseId,
      });
      console.log(response);
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (response.status === StatusCodes.OK) {
        let reloadList = await getLicenseList();
        setLicenseList_CTX(reloadList.data.licenseList);
        setLicenseAl_CTX(reloadList.data);
        setOpenRevoke(false);
        setLoaderState(false);
        setOpenRevokeSnack(true);
        setTimeout(() => {
          setOpenRevokeSnack(false);
        }, 2000);
        // setAge('')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLicense = async () => {
    setLoaderState(true);
    try {
      let response = await deleteLic({
        licenseNo: licenseId,
      });
      console.log(response);
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (response.status === StatusCodes.NOT_FOUND) {
        setLoaderState(false);
        setError(response.data.message);
      }
      if (response.status === StatusCodes.OK) {
        let reloadList = await getLicenseList();
        setLoaderState(false);
        setOpenDeleteSnack(true);
        setOpenDeleteDialog(false);
        setTimeout(() => {
          setLicenseList_CTX(reloadList.data.licenseList);
          setLicenseAl_CTX(reloadList.data);
          setOpenDeleteSnack(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {/* Table Body UI Start */}
      <Slide in>
        <TableRow>
          <TableCell sx={{ color: " #171C26", fontWeight: "600" }} align="left">
            {license.licenseNo}
          </TableCell>
          {license.email === null ? (
            <>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#171C26",
                  }}
                >
                  {license.licenseType}{" "}
                  {license.licenseType === "Business"
                    ? `- ${license.allowedUserCount}`
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#171C26",
                  }}
                >
                  Workspace Count - {license?.allowedWorkspaceCount}
                </Typography>
                {license.status === "Pending" ||
                license.status === "Requested" ? (
                  ""
                ) : (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#687182",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Valid Upto: {validDate.getDate()}{" "}
                    {validDate.toDateString().split(" ")[1]}{" "}
                    {validDate.getFullYear()}
                  </Typography>
                )}
              </TableCell>
              <TableCell colSpan={1} sx={{ textAlign: "left" }}>
                <Typography
                  // className={styles.unallo}
                  onClick={() => {
                    license.status === "Pending" &&
                    license.licenseType === "Business" ? (
                      handleModule(license.licenseNo)
                    ) : license.status === "Pending" &&
                    license.licenseType === "Individual" ? (
                      handleModule(license.licenseNo)
                    ) : license.licenseType === "Business" &&
                      license.status === "Requested" ? (
                      <></>
                    ) : (
                      handleClickOpen()
                    );
                  }}
                  sx={{
                    color: "black",
                    cursor: license.licenseType === "Business" &&
                    license.status === "Requested" ?  'default' : "pointer",
                    fontSize: "14px",
                    "&:hover": {
                      textDecoration:
                        license.status === "Pending" &&
                        license.licenseType === "Business"
                          ? "underline"
                          : license.licenseType === "Business" &&
                            license.status === "Requested"
                          ? "none"
                          : "underline",
                    },
                  }}
                >
                  {/* {currentDate <= validDate
                    ? "Click Here To Allocate"
                    : license.status === "Pending" || currentDate <= validDate
                    ? "Payment required"
                    : "Processing"} */}
                    {
                    license.status === "Pending" 
                    ? "Payment required"
                    : license.status === 'Requested' ?  "Processing" : license.status === 'Expired' ? 'Click here to renew' : 'Click Here To Allocate'}
                </Typography>
              </TableCell>
              <TableCell align="left">{license.email}</TableCell>
              <TableCell align="left">
                <Box
                  sx={{
                    width: "max-content",
                    backgroundColor:
                      license.status === "Active"
                        ? "#E1FCEF"
                        : license.status === "Unallocated"
                        ? "#F0F1FA"
                        : license.status === "Pending"
                        ? "#FFF3C5"
                        : license.status === "Expired"
                        ? "#FAF0F3"
                        : "#eeeeee",
                    borderRadius: "10px",
                    padding: "2px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        license.status === "Active"
                          ? "#14804A"
                          : license.status === "Unallocated"
                          ? "#4F5AED"
                          : license.status === "Pending"
                          ? "#F7B700"
                          : license.status === "Expired"
                          ? "#D12953"
                          : "grey",
                    }}
                  >
                    {license.status}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography sx={{ display: "flex", gap: "6px" }}>
                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.webModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Web
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.mobileModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Mobile
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.desktopModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Desktop
                    </Typography>
                  </Box>
                </Typography>
              </TableCell>

              <TableCell align="left">
                <Tooltip
                  // title={
                  //   <Typography sx={{ fontSize: "12px" }}>
                  //     {license.status === "Pending" ||
                  //     license.status === "Expired" ||
                  //     license.status === "Requested"
                  //       ? "Retry Payment"
                  //       : "Renew licence"}
                  //   </Typography>
                  // }
                  title={
                    <Typography sx={{ fontSize: "12px" }}>
                  Renew licence
                    </Typography>
                  }
                  arrow
                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                      pointerEvents:
                        license.licenseType === "Business" &&
                        license.status === "Pending"
                          ? "none"
                          :  license.licenseType === "Individual" &&
                          license.status === "Pending"
                            ? "none" : license.licenseType === "Business" &&
                            license.status === "Requested"
                          ? "none"
                          : "all",
                      opacity:
                        license.licenseType === "Business" &&
                        license.status === "Pending"
                          ? "0.1"
                          :  license.licenseType === "Individual" &&
                          license.status === "Pending"
                            ? "0.1" : license.licenseType === "Business" &&
                            license.status === "Requested"
                          ? "0.1"
                          : "1",
                    }}
                    onClick={handleOpenRenew}
                  >
                    <IconRenew />
                  </Typography>
                </Tooltip>
              </TableCell>
              {license.status === "Pending" ||
              license.status === "Requested" ||
              license.status === "Expired" ? (
                <>
                  <TableCell>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "12px" }}>
                          Delete licence
                        </Typography>
                      }
                      arrow
                    >
                      <Typography
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          setLicenseId(license.licenseNo);
                          console.log(license.status);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <DeleteIcon color="error" />
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "12px" }}>
                          Allocate licence
                        </Typography>
                      }
                      arrow
                    >
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          handleClickOpen();
                          setreassignSnack(true);
                        }}
                      >
                        <IconReassign />
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </>
              )}
              <TableCell>
                {/* <Tooltip
                    title={
                      <Typography sx={{ fontSize: "12px" }}>Details</Typography>
                    }
                    arrow
                  > */}
                <Typography sx={{ opacity: 0.1 }}>
                  <IconDetails />
                </Typography>
                {/* </Tooltip> */}
                <Popover
                  anchorEl={anchorEl}
                  open={openDetail}
                  onClose={handleCloseDetail}
                  PaperProps={{
                    style: {
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      padding: "8px 11px",
                      gap: "4px",
                      borderRadius: 6,
                    },
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "2px",
                      textTransform: "capitalize",
                    }}
                  >
                    Name:{" "}
                    <Typography sx={{ color: "black", fontSize: "14px" }}>
                      {license.firstName}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "6px",
                    }}
                  >
                    Phone:{" "}
                    <Typography sx={{ color: "black", fontSize: "14px" }}>
                      {license.phoneNum}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "3px",
                    }}
                  >
                    Valid Upto:{" "}
                    <Typography sx={{ color: "black", fontSize: "14px" }}>
                      {validDate.getDate()}{" "}
                      {validDate.toDateString().split(" ")[1]}{" "}
                      {validDate.getFullYear()}
                    </Typography>
                  </Typography>
                </Popover>
              </TableCell>
            </>
          ) : (
            <>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#171C26",
                  }}
                >
                  {license.licenseType}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#171C26",
                  }}
                >
                  Workspace Count - {license?.allowedWorkspaceCount}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#687182",
                    whiteSpace: "nowrap",
                  }}
                >
                  Valid Upto: {validDate.getDate()}{" "}
                  {validDate.toDateString().split(" ")[1]}{" "}
                  {validDate.getFullYear()}
                </Typography>
              </TableCell>

              <TableCell
                onMouseOver={() => setOpenCopyHover(true)}
                onMouseOut={() => setOpenCopyHover(false)}
                align="left"
              >
                <Tooltip
                  disableHoverListener
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "white",
                        width: "320px",
                        fontSize: "12px",
                        boxShadow: "0px 0px 5px 0px black",
                      },
                    },
                  }}
                  title={
                    <span style={{ color: "black", fontSize: "12px" }}>
                      {license.licenseKey}
                    </span>
                  }
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      display: "flex",
                      cursor: "pointer",
                      gap: "7px",
                      alignItems: "center",
                    }}
                  >
                    {finalLicense.substring(0, 19)}...
                    <Tooltip
                      disableHoverListener={
                        license.status === "Expired" ? true : false
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "white",
                            width: "320px",
                            fontSize: "12px",
                            boxShadow: "0px 0px 5px 0px black",
                          },
                        },
                      }}
                      title={
                        <span style={{ color: "black" }}>
                          {license.licenseKey}
                        </span>
                      }
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "22px",
                          color: text_col.col_primary,
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "12px" }}>Copy</Typography>
                      }
                      arrow
                    >
                      <ContentCopyIcon
                        sx={{
                          marginLeft: "6%",
                          cursor: "pointer",
                          fontSize: "20px",
                          visibility: openCopyHover ? "visible" : "hidden",
                        }}
                        onClick={() => {
                          handleCopy();
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Tooltip>
              </TableCell>

              <TableCell align="left">{license.email}</TableCell>

              <TableCell align="left">
                <Box
                  sx={{
                    width: "max-content",
                    backgroundColor:
                      license.status === "Active"
                        ? "#E1FCEF"
                        : license.status === "Unallocated"
                        ? "#F0F1FA"
                        : license.status === "Pending"
                        ? "#FFF3C5"
                        : license.status === "Expired"
                        ? "#FAF0F3"
                        : "white",
                    borderRadius: "10px",
                    padding: "2px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        license.status === "Active"
                          ? "#14804A"
                          : license.status === "Unallocated"
                          ? "#4F5AED"
                          : license.status === "Pending"
                          ? "#F7B700"
                          : license.status === "Expired"
                          ? "#D12953"
                          : "white",
                    }}
                  >
                    {/* {license.status === "Active" ? "Allocated" : license.status} */}
                    {license.status}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography sx={{ display: "flex", gap: "6px" }}>
                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.webModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Web
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.mobileModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Mobile
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "max-content",
                      backgroundColor: "#E9EDF5",
                      borderRadius: "10px",
                      padding: "2px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#5A6376",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {license.desktopModule ? (
                        <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                      ) : (
                        <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                      )}{" "}
                      Desktop
                    </Typography>
                  </Box>
                </Typography>
              </TableCell>

              <TableCell align="left">
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "12px" }}>
                      Renew licence
                    </Typography>
                  }
                  arrow
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={handleOpenRenew}
                  >
                    <IconRenew />
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "12px" }}>
                      Revoke licence
                    </Typography>
                  }
                  arrow
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenRevoke(true);
                      setLicenseId(license.licenseNo);
                    }}
                  >
                    <IconRevoke />
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "12px" }}>
                      Additional info
                    </Typography>
                  }
                  arrow
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                  >
                    <IconDetails />
                  </Typography>
                </Tooltip>
                <Popover
                  anchorEl={anchorEl}
                  open={openDetail}
                  onClose={handleCloseDetail}
                  PaperProps={{
                    style: {
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px 13px",
                      gap: "4px",
                      borderRadius: 6,
                    },
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Additional Info:
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "2px",
                      textTransform: "capitalize",
                    }}
                  >
                    Name:{" "}
                    <Typography sx={{ color: "black", fontSize: "14px" }}>
                      {license.firstName}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "6px",
                    }}
                  >
                    Phone:{" "}
                    {license.phoneNum !== "" ? (
                      <Typography sx={{ color: "black", fontSize: "14px" }}>
                        {license.phoneNum}
                      </Typography>
                    ) : (
                      <Typography sx={{ textAlign: "center", width: "28%" }}>
                        -
                      </Typography>
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: text_col.col_primary,
                      display: "flex",
                      fontSize: "14px",
                      gap: "3px",
                    }}
                  >
                    Valid Upto:{" "}
                    <Typography sx={{ color: "black", fontSize: "14px" }}>
                      {validDate.getDate()}{" "}
                      {validDate.toDateString().split(" ")[1]}{" "}
                      {validDate.getFullYear()}
                    </Typography>
                  </Typography>
                </Popover>
              </TableCell>
            </>
          )}
        </TableRow>
      </Slide>

      <Box>
        <BootstrapDialog
          open={openRevoke}
          onClose={() => {
            setOpenRevoke(false);
            setLoaderState(false);
          }}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "340px",
              maxHeight: "100%",
              // height: "73%",
              width: "22%",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              // width: "100%",
              height: "20%",
              paddingTop: "4%",
              gap: "15px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "20%",
                width: "91%",
                display: "flex",
                alignItems: "center",
                paddingLeft: "8px",
              }}
            >
              <Typography sx={{ fontSize: "19px" }}>Revoke Licence</Typography>
              <CloseIcon
                onClick={() => {
                  setOpenRevoke(false);
                  setLoaderState(false);
                }}
                style={{
                  cursor: "pointer",
                  color: "grey",
                  position: "absolute",
                  right: "4%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box width={"18px"}></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "86%",
                  gap: "2px",
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  Please confirm to remove the following licence :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}>
                    Assigned To:
                  </Typography>{" "}
                  {!license.email ? "Not yet assigned" : license.email}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      Licence Type:
                    </Typography>{" "}
                    {license.licenseType}
                  </Typography>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}> Modules:</Typography>{" "}
                  {license.webModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Web ,
                  {license.mobileModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Mobile ,
                  {license.desktopModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Desktop
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    // gap: "4px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}> Valid Upto</Typography>{" "}
                  : {validDate.getDate()}{" "}
                  {validDate.toDateString().split(" ")[1]}{" "}
                  {validDate.getFullYear()}
                </Typography>
              </Box>
              <Box width={"18px"}></Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "3%",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                disabled={getLoaderState}
                sx={{ width: "130px" }}
                onClick={() => revokeLicense()}
              >
                Revoke{" "}
                {getLoaderState ? (
                  <CircularProgress
                    size="1.2em"
                    sx={{
                      position: "absolute",
                      right: "0",
                      mr: 1,
                      color: "red",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
            <Box sx={{ height: "4%" }}></Box>
          </Box>
        </BootstrapDialog>
      </Box>

      <Box>
        <BootstrapDialog
          open={openDeleteDialog}
          onClose={() => {
            setOpenDeleteDialog(false);
            // setLoaderState(false);
            setError("");
          }}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "340px",
              maxHeight: "100%",
              // height: "73%",
              width: "22%",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              // width: "100%",
              height: "20%",
              paddingTop: "4%",
              gap: "15px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "20%",
                width: "91%",
                display: "flex",
                alignItems: "center",
                paddingLeft: "8px",
              }}
            >
              <Typography sx={{ fontSize: "19px" }}>Delete Licence</Typography>
              <CloseIcon
                onClick={() => {
                  setOpenDeleteDialog(false);
                  setLoaderState(false);
                  setError("");
                }}
                style={{
                  cursor: "pointer",
                  color: "grey",
                  position: "absolute",
                  right: "4%",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ color: "red" }}>{error}</Typography>
              <Box width={"18px"}></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "86%",
                  gap: "2px",
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  Please confirm to delete the following licence :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}>
                    Assigned To:
                  </Typography>{" "}
                  {!license.email ? "Not yet assigned" : license.email}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      Licence Type:
                    </Typography>{" "}
                    {license.licenseType}
                  </Typography>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px" }}> Modules:</Typography>{" "}
                  {license.webModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Web ,
                  {license.mobileModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Mobile ,
                  {license.desktopModule ? (
                    <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                  ) : (
                    <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                  )}
                  Desktop
                </Typography>
                {/* <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      // gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}> Valid Upto</Typography>{" "}
                    : {validDate.getDate()}{" "}
                    {validDate.toDateString().split(" ")[1]}{" "}
                    {validDate.getFullYear()}
                  </Typography> */}
              </Box>
              <Box width={"18px"}></Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "3%",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                disabled={getLoaderState}
                sx={{ width: "130px" }}
                onClick={deleteLicense}
              >
                Delete{" "}
                {getLoaderState ? (
                  <CircularProgress
                    size="1.2em"
                    sx={{
                      position: "absolute",
                      right: "0",
                      mr: 1,
                      color: "red",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
            <Box sx={{ height: "4%" }}></Box>
          </Box>
        </BootstrapDialog>
      </Box>

      <Box>
        <BootstrapDialog
          onClose={handleCloseRenew}
          aria-labelledby="customized-dialog-title"
          open={openRenew}
          PaperProps={{ style: { minWidth: "340px" } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <BootstrapDialogTitle
              sx={{
                width: "100%",
                height: "42px",
                fontSize: "19px",
                marginTop: "5px",
              }}
              id="customized-dialog-title"
              onClose={handleCloseRenew}
            >
              {/* {license.status === "Pending" ||
              license.status === "Expired" ||
              license.status === "Requested"
                ? "Retry Payment"
                : "Renew Licence"} */}
                Renew Licence
            </BootstrapDialogTitle>

            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                component="h2"
                sx={{
                  color: "red",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  fontSize: "10px",
                }}
              >
                {error}
              </Typography>
            </Box>

            <Box
              sx={{
                minWidth: "292px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              {/* <FormControl fullWidth>
                  <InputLabel id="card2">Select Card</InputLabel>
                  <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    value={age}
                    label="Select Card"
                    onChange={handleChange}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#eaeaea",
                      marginBottom: "11px",
                    }}
                  >
                    {" "}
                    {getCardsList_CTX.map((selectCard, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            handleSelect(selectCard);
                          }}
                          value={`************${selectCard.cardNo.substring(
                            4,
                            8
                          )}`}
                        >{`************${selectCard.cardNo.substring(
                          4,
                          8
                        )}`}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="freq">Payment Mode</InputLabel>
                <Select
                  labelId="frequencyF"
                  id="demo-simple-selectF"
                  value={`${license.licenseType === 'Business' ? 'Offline' : manualRenew}`}
                  label="Payment Mode"
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "#eaeaea",
                    width: "100%",
                  }}
                >
                  {license.licenseType === 'Business' ? Array.of("Offline").map((selecters, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setManualRenew(selecters);
                        }}
                        value={`${selecters}`}
                      >
                        {selecters}
                      </MenuItem>
                    );
                  }) : Array.of( "Online", "Offline").map((selecters, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setManualRenew(selecters);
                        }}
                        value={`${selecters}`}
                      >
                        {selecters}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="freq">Select Frequency</InputLabel>
                <Select
                  labelId="frequencyF"
                  id="demo-simple-selectF"
                  value={`${frequencyRenew}`}
                  label="Select Frequency"
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "#eaeaea",
                    width: "100%",
                  }}
                >
                  {selectsFRenew.map((selecters, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleSelectFRenew(selecters);
                        }}
                        value={`${selecters.frequency}`}
                      >
                        {selecters.frequency}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                marginRight: "92px",
                marginLeft: "82px!important",
              }}
            >
              <ButtonMaterial
                onClick={verifyInputRenew}
                variant="contained"
                disabled={getLoaderState}
                disableElevation
                sx={{
                  pr: 2,
                  height: "44px",
                  width: "127px",
                  borderRadius: "5px",
                }}
              >
                {/* {license.status === "Pending" ||
                license.status === "Expired" ||
                license.status === "Requested"
                  ? "Retry"
                  : "Renew"} */}
                    Renew
                {getLoaderState ? (
                  <CircularProgress
                    size="1.2em"
                    sx={{
                      position: "absolute",
                      right: "0",
                      mr: 1,
                      color: text_col.col_primary,
                    }}
                  />
                ) : (
                  ""
                )}
              </ButtonMaterial>
            </Box>
          </Box>
          <Box sx={{ height: "21px" }}></Box>
        </BootstrapDialog>
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={openRevokeSnack}
          onClose={() => setOpenRevokeSnack(false)}
          TransitionComponent={transition}
          message={"Licence revoked successfully"}
          autoHideDuration={2000}
          bodystyle={{ minWidth: 20 }}
        />
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={openDeleteSnack}
          onClose={() => setOpenDeleteSnack(false)}
          TransitionComponent={transition}
          message={"Licence deleted successfully"}
          autoHideDuration={1000}
          bodystyle={{ minWidth: 20 }}
        />
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={openCopy}
          onClose={handleCloseSnack}
          TransitionComponent={transition}
          message={
            license.status === "Expired"
              ? "Licence expired! please renew"
              : "Licence key copied successfully"
          }
          key={transition ? transition.name : ""}
          autoHideDuration={2000}
          bodystyle={{ minWidth: 20 }}
        />
      </Box>

      <Box>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          PaperProps={{ width: "auto" }}
        >
          <BootstrapDialogTitle
            sx={{ textAlign: "center", height: "55px" }}
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {license.licenseKey === null
              ? "ALLOCATE LICENCE"
              : "REASSIGN LICENCE"}
          </BootstrapDialogTitle>
          <Box
            sx={{
              width: "99%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              component="h2"
              sx={{
                color: "red",
                textTransform: "uppercase",
                fontWeight: "bold",
                letterSpacing: "2px",
                fontSize: "9px",
              }}
            >
              {error}
            </Typography>
          </Box>
          <DialogContent dividers>
            <Box
              display={"flex"}
              flexDirection="column"
              alignItems={"center"}
              gap="11px"
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                width={"100%"}
                gap="13px"
              >
                <TextField
                  fullWidth
                  error={
                    firstName === ""
                      ? false
                      : firstName.length < 3
                      ? true
                      : false
                  }
                  helperText={
                    <Typography sx={{ fontSize: "10px", color: "red" }}>
                      {firstName === ""
                        ? ""
                        : firstName.length < 3
                        ? "Minimum 3-characters"
                        : ""}
                    </Typography>
                  }
                  value={allocationChange?.firstName}
                  required
                  onChange={handleChangeIn("firstName")}
                  onBlur={handleBlurIn("firstName")}
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  required
                  onChange={handleChangeIn("lastName")}
                  onBlur={handleBlurIn("lastName")}
                  value={allocationChange?.lastName?.replace(/[^A-Za-z]/gi, "")}
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                />
              </Box>
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"center"}
                gap="13px"
              >
                <TextField
                  fullWidth
                  error={
                    email === ""
                      ? false
                      : !validator.isEmail(email)
                      ? true
                      : false
                  }
                  helperText={
                    <Typography sx={{ fontSize: "10px", color: "red" }}>
                      {email === ""
                        ? ""
                        : !validator.isEmail(email)
                        ? "Please enter a valid email"
                        : ""}
                    </Typography>
                  }
                  required
                  onChange={handleChangeIn("email")}
                  onBlur={handleBlurIn("email")}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={
                    phone === ""
                      ? false
                      : !validator.isMobilePhone(phone)
                      ? true
                      : false
                  }
                  helperText={
                    <Typography sx={{ fontSize: "10px", color: "red" }}>
                      {phone === ""
                        ? ""
                        : !validator.isMobilePhone(phone)
                        ? "Phone number not valid"
                        : ""}
                    </Typography>
                  }
                  value={allocationChange?.phone?.replace(/[^0-9]/g, "")}
                  onChange={handleChangeIn("phone")}
                  onBlur={handleBlurIn("phone")}
                  id="outlined-basic"
                  label="Phone No"
                  variant="outlined"
                />
              </Box>
              <Box display={"flex"} flexDirection="column" width={"100%"}>
                <Typography sx={{ fontSize: "10px" }}>
                  Please Enter Your System Code*
                </Typography>

                <TextareaAutosize
                  required
                  onChange={handleTextarea}
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Please Enter Your System Code*"
                  style={{
                    resize: "none",
                    height: "159px",
                    width: "100%",
                    minWidth: "100%",
                    maxHeight: "101px",
                    maxWidth: "100%",
                  }}
                />
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  height={"24px"}
                  alignItems={"flex-end"}
                >
                  <Tooltip
                    open={openTooltip}
                    onOpen={() => setTooltip(true)}
                    onClose={() => setTooltip(false)}
                    title={
                      <Typography sx={{ fontSize: "12px" }}>
                        You will get this code from SoliteraTA tool after
                        installation on the desired system.
                      </Typography>
                    }
                  >
                    <InfoIcon
                      onClick={() => {
                        setTooltip(!openTooltip);
                      }}
                      sx={{ color: text_col.col_primary, cursor: "pointer" }}
                    />
                  </Tooltip>
                </Box>
              </Box>

              <DialogActions>
                <Box display={"flex"}>
                  <ButtonMaterial
                    onClick={() => {
                      verifyInputs();
                    }}
                    variant="contained"
                    disabled={
                      allocationChange?.firstName.length < 3
                        ? true
                        : !allocationChange?.lastName
                        ? true
                        : !allocationChange?.email
                        ? true
                        : !systemCode
                        ? true
                        : !validator.isEmail(allocationChange?.email)
                        ? true
                        : !validator.isMobilePhone(allocationChange?.phone) &&
                          allocationChange?.phone !== ""
                        ? true
                        : getLoaderState
                        ? true
                        : false
                    }
                    disableElevation
                    sx={{
                      mt: 1,
                      pr: 2,
                      width: "132px",
                      marginTop: "-18px",
                    }}
                  >
                    {license.licenseKey === null ? "ALLOCATE" : "REASSIGN"}{" "}
                    {getLoaderState ? (
                      <CircularProgress
                        size="1.2em"
                        sx={{
                          position: "absolute",
                          right: "0",
                          mr: 1,
                          color: text_col.col_primary,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </ButtonMaterial>
                </Box>
              </DialogActions>
            </Box>
          </DialogContent>
        </BootstrapDialog>
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={renewSnack}
          // onClose={()=>setRenewSnack(false)}
          TransitionComponent={transition}
          message={error}
          // key={transition ? transition.name : ""}
          autoHideDuration={2000}
          bodyStyle={{ minWidth: 20 }}
        />
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={allocateSnack}
          TransitionComponent={transition}
          message={`${
            reassignSnack
              ? "Licence reassign successfully"
              : "Licence Allocated successfully"
          }`}
          key={transition ? transition.name : ""}
          autoHideDuration={2000}
          bodyStyle={{ minWidth: 20 }}
        />
      </Box>
    </>
    // Table body ui end
  );
}
