import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Container,
  Paper,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
  tableCellClasses,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  TablePagination,
  TableFooter,
  FormControlLabel,
  Switch,
  Snackbar,
  Alert,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowUpward from "@mui/icons-material/ArrowUpward"
import Close from "@mui/icons-material/Close"
import Cloud from "@mui/icons-material/Cloud"
import Download from "@mui/icons-material/Download"
import { getUserDetails } from "../../services/user-controller";
import { getCertificateQuestions, getCertificateList, addCertificateQuestions, editCertificateQuestions, deleteCertificateQuestions, addCertificate, editCertificates, deleteCertificates, downloadQuestionTemplate } from "../../services/certificate-controller";

const SuperAdminDashboard = () => {
  const [deleteItemId, setDeleteItemId] = useState(null); // State to store item id being deleted
  const [certificates, setCertificates] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false); // Dialog state for adding/editing
  const [dialogType, setdialogType] = useState(""); // 'certificates' or 'questions'
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // 'certificates' or 'questions'
  const [certificatesPage, setCertificatesPage] = useState(0);
  const [certificatesRowsPerPage, setCertificatesRowsPerPage] = useState(5);
  const [questionsPage, setQuestionsPage] = useState(0);
  const [questionsRowsPerPage, setQuestionsRowsPerPage] = useState(5);
  const [activeSection, setActiveSection] = useState("certificates");
  const [isEditMode, setIsEditMode] = useState("");
  const [deleteCertificateName, setDeleteCertificateName] = useState("");
  const [useFileUpload, setUseFileUpload] = useState("");
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
  const [activeTab, setActiveTab] = React.useState("certificates");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const textContainerRef = React.useRef(null);

  const handleToggleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    toggleSection(newValue);
  };


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#8C52FF',
      color: theme.palette.common.black,
      fontWeight: 'bold',
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '120px',
    },
  }));

  const actionButtonStyle = {
    color: '#8C52FF',
  };

  React.useEffect(() => {
    const checkOverflow = () => {
      if (textContainerRef.current) {
        const container = textContainerRef.current;
        setIsOverflowing(container.scrollHeight > container.clientHeight || container.scrollWidth > container.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const formatDateTime = (date, locale = 'en-US') => {
    if (!date) return "NA"; // Handle empty date

    const formattedDate = new Date(date).toLocaleDateString(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const formattedTime = new Date(date).toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Set to false for 24-hour time format
    });

    return `${formattedDate} ${formattedTime}`;
  };

  const CellWithTooltip = ({ children }) => (
    <Tooltip title={children} placement="top"
      arrow
      enterDelay={500}
      leaveDelay={50}>
      <StyledTableCell>{children}</StyledTableCell>
    </Tooltip>
  );

  useEffect(() => {
    authenticateUserSession();
    fetchQuestions();
    fetchCertificates();
  }, []);

  const handleCertificatesPageChange = (event, newPage) => {
    setCertificatesPage(newPage);
  };

  const handleCertificatesRowsPerPageChange = (event) => {
    setCertificatesRowsPerPage(parseInt(event.target.value, 10));
    setCertificatesPage(0);
  };

  const handleQuestionsPageChange = (event, newPage) => {
    setQuestionsPage(newPage);
  };

  const handleQuestionsRowsPerPageChange = (event) => {
    setQuestionsRowsPerPage(parseInt(event.target.value, 10));
    setQuestionsPage(0);
  };

  useEffect(() => {
    // Reset the toggle and file-related state when switching dialog types
    setUseFileUpload(false);
    setSelectedFileName("");
  }, [dialogType]);

  const authenticateUserSession = async () => {
    try {
      setIsAuthenticating(true);
      const response = await getUserDetails();
      if (response.data) {
        // setUser(response.data);
      } else {
        console.error("User not authenticated:", response);
      }
    } catch (error) {
      console.error("Error authenticating user:", error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await getCertificateQuestions(); // Ensure correct API call for questions
      if (response.data && Array.isArray(response.data.list)) {
        setQuestions(response.data.list);
      } else {
        setQuestions([]); // Default to an empty array if the response is invalid
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
      setQuestions([]); // Default to an empty array if there's an error
    }
  };

  const fetchCertificates = async () => {
    try {
      const response = await getCertificateList(); // Ensure correct API call for certificates
      if (response.data && Array.isArray(response.data)) {
        setCertificates(response.data);
      } else {
        setCertificates([]); // Default to an empty array if the response is invalid
      }
    } catch (error) {
      console.error("Error fetching certificates:", error);
      setCertificates([]); // Default to an empty array if there's an error
    }
  };

  const toggleSection = (section) => {
    setActiveSection(section);
  };

  const editItem = (item) => {
    console.log("under edit item");
    setIsEditMode(true);
    setEditingItem(item);
    setdialogType(item.question ? "questions" : "certificates");
    setAddEditDialogOpen(true);
  }

  const deleteItem = async (item) => {
    console.log("under delete item");
    setdialogType(item.question ? "questions" : "certificates");
    setDeleteDialogOpen(true);
    if (dialogType === "certificates") {
      console.log(item);
      console.log(item.name);
      setDeleteCertificateName(item.name);  // Fetch certificates if the type is certificates
    } else {
      setDeleteItemId(item);  // Set the item to be deleted when the dialog is opened  // Fetch questions if the type is questions
    }
  }

  const downloadQuestionTemplateFunc = async () => {
    await downloadQuestionTemplate();
  }

  const deleteItemDialogQuestions = async () => {
    let body = {
      ids: [deleteItemId.id]
    }
    await deleteCertificateQuestions(body);
    fetchQuestions();
    setDeleteDialogOpen(false);
  };

  const deleteItemDialogCertificates = async () => {
    console.log(deleteCertificateName);
    let body = {
      name: deleteCertificateName
    }
    console.log(body);
    try {
      await deleteCertificates(body);
      fetchCertificates();
      setSnackbarMessage("Certificate deleted successfully!");
      setSnackbarSeverity("success");
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting certificate:", error);
      setSnackbarMessage("Failed to delete certificate. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
  };

  const addItem = (type) => {
    setIsEditMode(false);
    const newItem = { // New items should have an empty id initially
      question: "",
      ans1: "",
      ans2: "",
      ans3: "",
      ans4: "",
      correctAns: "",
      certificateName: "",
      languageCode: "",
    };

    const newItemCertificate = { // New items should have an empty id initially
      name: "",
      description: "",
      parentName: "",
      durationInMonths: "",
      testDurationInMins: "",
      quizSize: "",
      passScore: "",

    };

    if (type === "questions") {
      setQuestions((prevQuestions) => [...prevQuestions, newItem]);
      console.log("under questions list of add item");

      console.log("before going in setEditingItem");
      setEditingItem(newItem);
      setdialogType(type);
      setAddEditDialogOpen(true);
      console.log("after going in setEditingItem");
    } else if (type === "certificates") {
      setCertificates((prevCertificates) => [...prevCertificates, newItemCertificate]);

      console.log("before going in setEditingItem");
      setEditingItem(newItemCertificate);
      setdialogType(type);
      setAddEditDialogOpen(true);
      console.log("after going in setEditingItem");
    }
  };

  const saveItemOfQuestions = async () => {
    // Check if editingItem is null
    if (!editingItem) {
      setSnackbarSeverity('error');
      setSnackbarMessage("No item to save.");
      setSnackbarOpen(true);
      return;
    }

    const { question, ans1, ans2, ans3, ans4, correctAns, certificateName } = editingItem;

    if (dialogType === "questions") {
      // Check if the correct answer matches one of the provided options
      if (![ans1, ans2, ans3, ans4].includes(correctAns)) {
        setSnackbarSeverity('error');
        setSnackbarMessage("Error: The correct answer must match one of the provided options.");
        setSnackbarOpen(true);
        return;
      }
    }

    try {
      let response;

      // Check if editing an existing item or adding a new one
      if (editingItem.id) {
        console.log("Editing the item...");
        response = await editCertificateQuestions(editingItem);

        // Handle success response for editing
        if (response.status === 200 && response.data) {
          // Delay the snackbar trigger slightly to ensure proper state updates
          setTimeout(() => {
            setSnackbarSeverity('success');
            setSnackbarMessage("Question edited successfully.");
            setSnackbarOpen(true); // Show the snackbar on success
          }, 100);
          await fetchQuestions(); // Fetch updated questions after editing
          setAddEditDialogOpen(false); // Close the dialog on success

        } else {
          // Handle error response when editing fails
          console.error('Invalid response data:', response.data);
          setSnackbarSeverity('error');

          // Extract and format error messages from response data
          let errorMessage = "An error occurred while editing the item.";

          // Check if there's a main message and fields errors
          if (response.data?.message && response.data?.fields) {
            // If both main message and fields are present, prioritize fields messages
            errorMessage = Object.entries(response.data.fields)
              .map(([field, message]) => `${field}: ${message}`)
              .join('\n'); // Join the messages with line breaks for better readability
          } else if (response.data?.message) {
            // If only the main message is present, use it
            errorMessage = response.data.message;
          } else if (response.data?.fields) {
            // If only fields errors are present, format them
            errorMessage = Object.entries(response.data.fields)
              .map(([field, message]) => `${field}: ${message}`)
              .join('\n');
          }

          setSnackbarMessage(errorMessage);
          setAddEditDialogOpen(true); // Keep the dialog open on error
          setSnackbarOpen(true); // Show the error snackbar
        }
      } else {
        // Handle adding a new item
        let body = {};

        if (selectedFileName) {
          // Adding via file upload
          body = { file: selectedFileName };
          console.log("Adding a new item via file upload:", selectedFileName);
        } else {
          // Adding via form data
          body = {
            'list[0].question': question,
            'list[0].ans1': ans1,
            'list[0].languageCode': editingItem.languageCode,
            'list[0].ans2': ans2,
            'list[0].ans3': ans3,
            'list[0].ans4': ans4,
            'list[0].correctAns': correctAns,
            'list[0].certificateName': certificateName,
          };
          console.log("Adding a new item via form data:", body);
        }

        response = await addCertificateQuestions(body);

        // Handle success response for adding
        if (response.status === 200 && response.data) {
          await fetchQuestions(); // Fetch updated questions after adding
          setAddEditDialogOpen(false); // Close the dialog on success

          // Delay the snackbar trigger slightly to ensure proper state updates
          setTimeout(() => {
            setSnackbarSeverity('success');
            setSnackbarMessage("Question added successfully.");
            setSnackbarOpen(true); // Show the snackbar on success
          }, 100);

          setEditingItem(null); // Clear editing item only on success
        } else {
          // Handle error response when adding fails
          console.error('Invalid response data:', response.data);
          setSnackbarSeverity('error');
          setSnackbarMessage(response.data?.message || "An error occurred while saving the item.");
          setAddEditDialogOpen(true); // Keep the dialog open on error
          setSnackbarOpen(true); // Show the error snackbar
        }
      }
    } catch (error) {
      // Handle any unexpected errors during the process
      console.error("Error saving item:", error);
      setSnackbarSeverity('error');
      setSnackbarMessage("An error occurred while saving the item.");
      setSnackbarOpen(true); // Show the error snackbar
      setAddEditDialogOpen(true); // Keep the dialog open on error
    } finally {
      // Clear selected file name, but don't clear editing item
      setSelectedFileName("");
    }
  }

  const truncateMessage = (message, maxLength = 100) => {
    // Truncate the message if it's too long and add ellipsis
    return message.length > maxLength ? `${message.slice(0, maxLength)}...` : message;
  };

  const saveItemOfCertifications = async () => {
    try {
      let response;

      if (isEditMode) {
        console.log("Editing the item...");
        response = await editCertificates(editingItem);

        if (response.status === 200 && response.data) {
          setTimeout(() => {
            setSnackbarSeverity('success');
            setSnackbarMessage("Certificate edited successfully.");
            setSnackbarOpen(true);
            setAddEditDialogOpen(false);
          }, 100);
        } else {
          handleErrorResponse(response.data);
          setAddEditDialogOpen(true);
        }
      } else {
        const body = {
          name: editingItem.name,
          description: editingItem.description,
          parentName: editingItem.parentName,
          quizSize: editingItem.quizSize,
          passScore: editingItem.passScore,
          durationInMonths: editingItem.durationInMonths,
          testDurationInMins: editingItem.testDurationInMins,
        };

        console.log("Adding a new item for certificate");

        response = await addCertificate(body);

        if (response.status === 200 && response.data) {
          setTimeout(() => {
            setSnackbarSeverity('success');
            setSnackbarMessage("Certificate added successfully.");
            setSnackbarOpen(true);
            setAddEditDialogOpen(false);
          }, 100);
        } else {
          handleErrorResponse(response.data);
          setAddEditDialogOpen(true);
        }
      }

      await fetchCertificates();
    } catch (error) {
      console.error("Error saving item:", error);
      handleErrorResponse(error.response?.data);
      setAddEditDialogOpen(true);
    }
  };

  // Utility function to handle and format error messages
  const handleErrorResponse = (data) => {
    let errorMessage = "An error occurred while processing the request.";

    // Check if fields errors are present and prioritize them
    if (data?.fields) {
      errorMessage = Object.entries(data.fields)
        .map(([field, message]) => `${field}: ${message}`)
        .join('\n'); // Concatenate field errors with line breaks
    } else if (data?.message) {
      // Use the main message if field errors are not present
      errorMessage = data.message;
    }

    setSnackbarSeverity('error');
    setSnackbarMessage(errorMessage);
    setSnackbarOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditingItem((prevEditingItem) => ({
      ...prevEditingItem,
      [name]: value, // Update the appropriate field in editingItem
    }));

    // If you need to treat the value as a number later, you can parse it
    const parsedValue = parseFloat(value.trim());

    // Handle numerical logic if necessary
    if (!isNaN(parsedValue)) {
      console.log("Parsed numerical value:", parsedValue);
    }
  };

  const saveItem = async (type) => {
    if (type === "questions") {
      saveItemOfQuestions();
    } else {
      saveItemOfCertifications();

    }
  };

  if (isAuthenticating) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container maxWidth="xl" disableGutters>
      <Box>
        {/* Headline Section */}
        <Box
          display="flex"
          flexDirection="column"
          backgroundColor="#F4F7FCBF"
          justifyContent="center"
          padding="18px 0px 20px 11px"
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#393939",
            }}
          >
            {"CERTIFICATIONS "}
          </Typography>
        </Box>

        {/* Tabs Section */}
        <Box display="block" mt={2}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="certifications and questions tabs"
            sx={{ ml: 3 }}
          >
            <Tab
              label="Certificates"
              value="certificates"
              sx={{
                fontWeight: activeTab === "certificates" ? "bold" : "normal",
              }}
            />
            <Tab
              label="Questions"
              value="questions"
              sx={{
                fontWeight: activeTab === "questions" ? "bold" : "normal",
              }}
            />
          </Tabs>
        </Box>


        <Box flex={1} ml={1} sx={{ position: "relative", width: "100%", height: "100%" }}>
          {activeSection === "certificates" && (
            <Box>
              <Box display="flex" justifyContent="flex-end" mb={2} mr={2}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#8C52FF' }}
                  startIcon={<AddIcon />}
                  onClick={() => addItem("certificates")}
                >
                  Add Certificate
                </Button>
              </Box>

              <TableContainer style={{ maxHeight: 450 }} component={Paper} sx={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
                <Table stickyHeader>
                  <TableHead sx={{ backgroundColor: '#8C52FF' }}>
                    <TableRow >
                      {['#', 'Certificate Name', 'Description', 'Parent Name', 'Duration (in Months)',
                        'Test Duration (in Minutes)', 'Quiz Size', 'Pass Score',
                        'Created At', 'Updated At', 'Action'].map((header, index) => (
                          <TableCell key={index}>
                            {header}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {certificates.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          No Data Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      certificates.slice(
                        certificatesPage * certificatesRowsPerPage,
                        certificatesPage * certificatesRowsPerPage + certificatesRowsPerPage
                      ).map((cert, index) => (
                        <TableRow key={index} sx={{ '&:nth-of-type(even)': { backgroundColor: '#f3f4f6' } }}>
                          <CellWithTooltip>{certificatesPage * certificatesRowsPerPage + index + 1}</CellWithTooltip>
                          <CellWithTooltip>{cert.name || "NA"}</CellWithTooltip>
                          <CellWithTooltip>{cert.description || "NA"}</CellWithTooltip>
                          <CellWithTooltip>{cert.parentName || "NA"}</CellWithTooltip>
                          <CellWithTooltip>{cert.durationInMonths || "NA"}</CellWithTooltip>
                          <CellWithTooltip>{cert.testDurationInMins || "NA"}</CellWithTooltip>
                          <CellWithTooltip>{cert.quizSize || "NA"}</CellWithTooltip>
                          <CellWithTooltip>{cert.passScore || "NA"}</CellWithTooltip>
                          <CellWithTooltip>{formatDateTime(cert.createdAt)}</CellWithTooltip>
                          <CellWithTooltip>{formatDateTime(cert.updatedAt)}</CellWithTooltip>
                          <TableCell sx={{ display: "flex", gap: 2 }}>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => editItem(cert)}
                                size="small"
                                sx={actionButtonStyle}

                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => deleteItem(cert, "certificates")}
                                size="small"
                                sx={{ color: '#FF5252' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer >
              <TableFooter >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={certificates.length}
                  rowsPerPage={certificatesRowsPerPage}
                  page={certificatesPage}
                  onPageChange={handleCertificatesPageChange}
                  onRowsPerPageChange={handleCertificatesRowsPerPageChange}
                />
              </TableFooter>
            </Box>
          )}

          {activeSection === "questions" && (
            <Box>
              <Box display="flex" justifyContent="flex-end" mb={2} mr={2}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#8C52FF" }}
                  startIcon={<AddIcon />}
                  onClick={() => addItem("questions")}
                >
                  Add Question
                </Button>
              </Box>

              <TableContainer component={Paper} style={{ maxHeight: 450 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Question</TableCell>
                      <TableCell>Option 1</TableCell>
                      <TableCell>Option 2</TableCell>
                      <TableCell>Option 3</TableCell>
                      <TableCell>Option 4</TableCell>
                      <TableCell>Correct Answer</TableCell>
                      <TableCell>Certificate Name</TableCell>
                      <TableCell>Language Code</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {questions.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No Data Found
                        </TableCell>
                      </TableRow>) : (
                      questions.slice(
                        questionsPage * questionsRowsPerPage,
                        questionsPage * questionsRowsPerPage + questionsRowsPerPage
                      ).map((question, index) => (
                        <TableRow key={index}>
                          <CellWithTooltip>{questionsPage * questionsRowsPerPage + index + 1}</CellWithTooltip>
                          <CellWithTooltip>{question.question}</CellWithTooltip>
                          <CellWithTooltip>{question.ans1}</CellWithTooltip>
                          <CellWithTooltip>{question.ans2}</CellWithTooltip>
                          <CellWithTooltip>{question.ans3}</CellWithTooltip>
                          <CellWithTooltip>{question.ans4}</CellWithTooltip>

                          <CellWithTooltip>{question.correctAns}</CellWithTooltip>
                          <CellWithTooltip>{question.certificateName}</CellWithTooltip>
                          <CellWithTooltip>{question.languageCode}</CellWithTooltip>
                          <TableCell sx={{ display: "flex", gap: 2 }}>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => editItem(question)}
                                size="small"
                                sx={actionButtonStyle}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => deleteItem(question)}
                                size="small"
                                sx={{ color: '#FF5252' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={questions.length}
                  rowsPerPage={questionsRowsPerPage}
                  page={questionsPage}
                  onPageChange={handleQuestionsPageChange}
                  onRowsPerPageChange={handleQuestionsRowsPerPageChange}
                />
              </TableFooter>
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          action={
            <>
              {isOverflowing && (
                <IconButton
                  size="small"
                  aria-label={isExpanded ? "Show Less" : "Show More"}
                  color="inherit"
                  onClick={handleToggleExpand}
                >
                  {isExpanded ? <ArrowUpward /> : <ArrowDownward />}

                </IconButton>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <Close fontSize="small" />
              </IconButton>

            </>
          }
          style={{
            maxWidth: '400px', // Adjust width as needed
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              whiteSpace: 'pre-line',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: isExpanded ? 'auto' : '100px', // Truncate when not expanded
            }}
          >
            {snackbarMessage}
          </div>
        </Alert>
      </Snackbar>

      <Dialog open={addEditDialogOpen} onClose={() => { setAddEditDialogOpen(false); setEditingItem(null); }}
      >
        <DialogTitle sx={{ backgroundColor: "#8C52FF", color: "white", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {dialogType === "questions"
            ? (editingItem?.id ? "Edit Question" : "Add Question")
            : (isEditMode ? "Edit Certificate" : "Add Certificate")
          }
          <IconButton
            edge="end"
            sx={{ color: "white" }}
            onClick={() => {
              setAddEditDialogOpen(false);
              // Conditionally fetch based on the dialog type
              if (dialogType === "questions") {
                fetchQuestions();
              } else if (dialogType === "certificates") {
                fetchCertificates(); // Use fetchCertificates() for certificates dialog
              }
            }}
            aria-label="close"
          >
            <Close onClick={() => { setSelectedFileName(""); }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>

          {dialogType === "questions" && !editingItem?.id && (
            <FormControlLabel
              control={
                <Switch
                  checked={useFileUpload}
                  onChange={(e) => setUseFileUpload(e.target.checked)}
                  color="primary"
                />
              }
              label={useFileUpload ? "Enter Data Individually" : "Bulk Upload"}
              sx={{
                mt: 1,
                paddingRight: useFileUpload ? '10em' : '0',
              }}
            />
          )}

          {useFileUpload && !isEditMode && dialogType === "questions" ? (
            <Box mt={2} textAlign="center">
              <Typography variant="h6" color="textSecondary" mb={2}>
                Upload a File
              </Typography>
              <Button
                variant="contained"
                component="label"
                color="primary"
                startIcon={<Cloud />}
                sx={{
                  backgroundColor: "#8C52FF",
                  color: "white",
                  textTransform: "none",
                  '&:hover': {
                    backgroundColor: "#7A45DB",
                  },
                }}
              >
                Choose File
                <input
                  type="file"
                  accept=".xlsx"
                  hidden
                  onChange={(e) => {
                    const file = e.target.files[0];
                    console.log(file, "file");
                    if (file) {
                      setSelectedFileName(file);
                      console.log("Selected file:", file.name);
                    }
                  }}
                />
              </Button>
              {selectedFileName && (
                <Typography variant="body2" color="textSecondary" mt={1}>
                  Selected file: {selectedFileName.name}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary" mt={1}>
                Supported formats: XLSX
              </Typography>
              <Button
                variant="outlined"
                startIcon={<Download />}
                onClick={downloadQuestionTemplateFunc}
                sx={{ mt: 2 }}
              >
                Download Template
              </Button>
            </Box>
          ) : (
            <>
              {dialogType === "questions" && (
                <Box>
                  <TextField
                    label="Question"
                    fullWidth
                    variant="standard"
                    value={editingItem?.question || ""}
                    onChange={handleInputChange}
                    margin="normal"
                    name="question"
                  />

                  {/* Grid layout for options */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Option 1"
                        variant="standard"
                        value={editingItem?.ans1 || ""}
                        onChange={handleInputChange}
                        margin="normal"
                        name="ans1"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Option 2"
                        variant="standard"
                        value={editingItem?.ans2 || ""}
                        onChange={handleInputChange}
                        margin="normal"
                        name="ans2"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Option 3"
                        variant="standard"
                        value={editingItem?.ans3 || ""}
                        onChange={handleInputChange}
                        margin="normal"
                        name="ans3"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Option 4"
                        variant="standard"
                        value={editingItem?.ans4 || ""}
                        onChange={handleInputChange}
                        margin="normal"
                        name="ans4"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  {/* Tooltip with FormControl for correct answer */}
                  <Tooltip
                    title={
                      !editingItem?.ans1 && !editingItem?.ans2 && !editingItem?.ans3 && !editingItem?.ans4
                        ? "Please fill in at least one option to select the correct answer."
                        : ""
                    }
                    disableHoverListener={
                      editingItem?.ans1 || editingItem?.ans2 || editingItem?.ans3 || editingItem?.ans4
                    }
                  >

                    {/* Select box for correct answer */}
                    <FormControl fullWidth margin="normal" variant="standard"
                      disabled={
                        !editingItem?.ans1 &&
                        !editingItem?.ans2 &&
                        !editingItem?.ans3 &&
                        !editingItem?.ans4
                      }>
                      <InputLabel>Correct Answer</InputLabel>
                      <Select
                        value={editingItem?.correctAns || ""}
                        onChange={handleInputChange}
                        name="correctAns"
                      >
                        <MenuItem value={editingItem?.ans1 || ""}>{editingItem?.ans1 || ""}</MenuItem>
                        <MenuItem value={editingItem?.ans2 || ""}>{editingItem?.ans2 || ""}</MenuItem>
                        <MenuItem value={editingItem?.ans3 || ""}>{editingItem?.ans3 || ""}</MenuItem>
                        <MenuItem value={editingItem?.ans4 || ""}>{editingItem?.ans4 || ""}</MenuItem>
                      </Select>
                    </FormControl>
                  </Tooltip>
                  <FormControl fullWidth margin="normal" variant="standard">
                    <InputLabel id="certificate-select-label">Certificate Name</InputLabel>
                    <Select
                      labelId="certificate-select-label"
                      value={editingItem?.certificateName || ""}
                      onChange={(e) => setEditingItem({ ...editingItem, certificateName: e.target.value })}
                      label="Certificate Name"
                      sx={{
                        padding: "10px 0px", // Padding to match the standard TextField variant
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)", // Bottom border to mimic TextField's standard variant
                      }}
                    >
                      {certificates.map((certificate) => (
                        <MenuItem key={certificate.id} value={certificate.name}>
                          {certificate.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Language Code"
                    fullWidth
                    variant="standard"
                    value={editingItem?.languageCode || ""}
                    onChange={(e) => setEditingItem({ ...editingItem, languageCode: e.target.value })}
                    margin="normal"
                  />
                </Box>
              )}

              {dialogType === "certificates" && (
                <Box>
                  <TextField
                    label="Certificate Name"
                    fullWidth
                    variant="standard"
                    value={editingItem?.name || ""}
                    onChange={(e) => setEditingItem({ ...editingItem, name: e.target.value })}
                    margin="normal"
                    style={{ display: isEditMode ? "none" : "block" }}
                  />

                  {isEditMode && (  // Show only if editing
                    <TextField
                      label="Certificate Name"
                      fullWidth
                      variant="standard"
                      value={editingItem?.newName || editingItem?.name || ""}
                      onChange={(e) => setEditingItem({ ...editingItem, newName: e.target.value })}
                      margin="normal"
                    />
                  )}
                  <TextField
                    label="Description"
                    fullWidth
                    variant="standard"
                    value={editingItem?.description || ""}
                    onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })}
                    margin="normal"
                  />
                  {/* Select component for Parent Name */}
                  <FormControl fullWidth margin="normal" variant="standard">
                    <InputLabel>Parent Name</InputLabel>
                    <Select
                      value={editingItem?.parentName || ""}
                      onChange={(e) => setEditingItem({ ...editingItem, parentName: e.target.value })}
                      label="Parent Name"
                    >
                      {/* Filter certificates to exclude the current certificate */}
                      {certificates.filter(cert => cert.name !== editingItem?.name).length > 0 ? (
                        certificates
                          .filter(cert => cert.name !== editingItem?.name) // Exclude self certificate
                          .map((cert) => (
                            <MenuItem key={cert.id} value={cert.name}>
                              {cert.name}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem value="N/A"></MenuItem> // Show N/A if no other certificates are available
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Duration (in Months)"
                    fullWidth
                    variant="standard"
                    type="number"
                    value={editingItem?.durationInMonths || ""}
                    onChange={(e) => setEditingItem({ ...editingItem, durationInMonths: e.target.value })}
                    margin="normal"
                  />
                  <TextField
                    label="Test Duration (in Minutes)"
                    fullWidth
                    variant="standard"
                    type="number"
                    value={editingItem?.testDurationInMins || ""}
                    onChange={(e) => setEditingItem({ ...editingItem, testDurationInMins: e.target.value })}
                    margin="normal"
                  />
                  <TextField
                    label="Quiz Size"
                    fullWidth
                    variant="standard"
                    type="number"
                    value={editingItem?.quizSize || ""}
                    onChange={(e) => setEditingItem({ ...editingItem, quizSize: e.target.value })}
                    margin="normal"
                  />
                  <TextField
                    label="Pass Score"
                    fullWidth
                    variant="standard"
                    type="number"
                    value={editingItem?.passScore || ""}
                    onChange={(e) => setEditingItem({ ...editingItem, passScore: e.target.value })}
                    margin="normal"
                  />
                </Box>

              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained"
            onClick={() => {
              setAddEditDialogOpen(false);
              setSelectedFileName("");
              // Conditionally fetch based on the dialog type
              if (dialogType === "questions") {
                fetchQuestions();
              } else if (dialogType === "certificates") {
                fetchCertificates(); // Use fetchCertificates() for certificates dialog
              }
            }}
            sx={{ backgroundColor: "#8C52FF" }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => saveItem(dialogType)} sx={{ backgroundColor: "#8C52FF" }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleClose}>
        <DialogTitle sx={{ backgroundColor: "#8C52FF", color: "white", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>Delete Item</span>
          <IconButton onClick={handleClose} sx={{ color: "white" }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#8C52FF" }} mb={2} mt={2} fontSize={20}>
            Do you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
              console.log(dialogType, "addEdit");
              if (dialogType === "certificates") {
                fetchCertificates();  // Fetch certificates if the type is certificates
              } else {
                fetchQuestions();  // Fetch questions if the type is questions
              }
            }}
            sx={{ backgroundColor: "#8C52FF", color: "white" }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              console.log(dialogType, "addEdit");
              if (dialogType === "certificates") {
                console.log("under certificate delete button ");
                deleteItemDialogCertificates();
                fetchCertificates();  // Fetch certificates if the type is certificates
              } else {
                deleteItemDialogQuestions();
                fetchQuestions();  // Fetch questions if the type is questions
              }
            }}
            sx={{ backgroundColor: "#8C52FF", color: "white" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SuperAdminDashboard;