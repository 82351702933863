import { API, API_HOST, API_METHOD, LicenseAPI } from "../../constants/api";
import {HttpRequest} from '../../utility/http-request';


export const updateLicense = async function(body){
    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.license + LicenseAPI.update
            // url: 'http://9e85-146-196-33-171.ngrok.io/soliterata/user/license/updateLicense'
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
} 


export const  getLicenseList = async function() {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            body: {},
            url: API_HOST.hostUrl + API.licenses,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }




}

export const  getLicenseListSuperadmin = async function() {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            body: {},
            url: API_HOST.hostUrl + API.licensesSuperAdmin,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }




}

export const  getLicenseBulk = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.licenses + API.licenseBulk,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  getPricing = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            body: body,
            url: API_HOST.hostUrl + API.pricing,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}



export const  licenseRe = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.licenses + API.licenseReassign,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }




}

export const  licenseRenew = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.licenses + API.licenseRenew,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }




}

export const  buyDemoLicense = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.licenses + API.freeLicence,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  licenceLimitations = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            body: body,
            url: API_HOST.hostUrl  + API.pricing + API.limitations,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  revokeLic = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.licenses + API.revoke,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  deleteLic = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.delete,
            body: body,
            url: API_HOST.hostUrl + API.licenses + API.delete,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  confirmPayment = async function(intentId) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            // body: body,
            url: API_HOST.hostUrl + API.licenses + API.confirm + `/${intentId}`,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  getLeads = async function(intentId) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            // body: body,
            url: API_HOST.hostUrl + API.licenses + API.leads,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  postLeads = async function(body) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.post,
            body: body,
            url: API_HOST.hostUrl + API.licenses + API.convert,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}

export const  getLicenseDetails = async function(licenseNo) {

    try{
        
        let http = new HttpRequest({
            method:API_METHOD.get,
            // body: body,
            url: API_HOST.hostUrl + API.licenses + `/${licenseNo}`,
            isAuthenticated : true
        }); 
        
        let res = await http.sendRequest();

        return res;
    }catch(e){
        if(e.response){
            return e.response;
        }else{
            return null;
        }
    }
}
