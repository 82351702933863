import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
// import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getUserDetails, SignInUser } from "../../services/user-controller";
import { StatusCodes } from "http-status-codes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ApplicationContext } from "../../context/applicationCtx";
import { useContext } from "react";
import OtpStep from "../verification";
import validator from "validator";
import { superAdminLicense } from "../../services/superadmin-controller";
import {
  ButtonMaterial,
  TextFieldTA,
  text_col,
} from "../../constants/constStyles";
import { useRef } from "react";

export default function SignIn() {
  const userFocus = useRef();
  let navigate = useNavigate();
  const loginQuery = useMediaQuery("(max-width:640px)");
  const [error, setError] = useState(null);
  const [getLoaderState, setLoaderState] = useState(false);
  // const [ usernameLogin,setUsernameLogin] = useState(null);
  const [password, setPassword] = useState(null);
  // const [focusPassword, setFocusPassword] = useState(false);
  const [ setFocusForError] = useState(false);
  const {
    getUserModuleLogin,
    setUserModuleLogin,
    setLoginToken,
    usernameLogin,
    setUsernameLogin,

    setActiveModule,
  } = useContext(ApplicationContext);

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  useEffect(() => {
    document.body.style.overflow = "auto";
    localStorage.setItem("verify", "no");

    getUserDetails()
      .then((apiData) => {
        if (apiData.status === StatusCodes.OK) {
          navigate("/licence");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const superAdminApiCall = async () => {
      try {
        let response = await superAdminLicense();
        console.log(response);
        if (response.status === StatusCodes.OK) {
          navigate("/superadmin");
        }
      } catch (error) {
        console.log(error);
      }
    };
    superAdminApiCall();
  }, [navigate]);

  document.addEventListener("keypress", (event) => {
    if (event.key === "Enter") {
      document.getElementById("entert").click();
    }
  });

  const routeChange = () => {
    navigate("/forgot-password");
    // setUserModule(1)
    setPassword("");
    setError("");
    setUsernameLogin(null);

  };

  const routeRegister = () => {
    navigate("/register");
    // setUserModule(2)
    setError("");
    setUsernameLogin(null);
    setPassword("");
  };

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const verifyInputs = async function () {
    if (!password) {
      setPassword("");
    }
    if (!usernameLogin) {
      setUsernameLogin("");
    }
    if (!usernameLogin && !password) {
      setFocusForError(false);
      console.log("entered");
    } else {
      if (!validator.isEmail(usernameLogin) || password.length < 8) {
        setFocusForError(true);
      } else {
        setLoaderState(true);

        try {
          let response = await SignInUser({
            password: password,
            username: usernameLogin,
          });
          if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
            setLoaderState(false);
            setError("Something went wrong");
          }
          if (
            response.status === StatusCodes.OK &&
            response.data.userRole === "SuperAdmin"
          ) {
            localStorage.setItem("api-key", response.data.authToken);
            localStorage.setItem("super-key", response.data.authToken);
            setActiveModule(0);
            navigate("/licence");
            console.log("working");
          }
          if (!response) {
            // alert('Something went wrong!')
            setError(`**Unable to connect to server!**`);
            setLoaderState(false);
          }
          if (response.data.authToken != null) {
            localStorage.setItem("api-key", response.data.authToken);
          }
          if (response.status === StatusCodes.MULTI_STATUS) {
            console.log("test");
            // navigate('/verification');
            // localStorage.setItem("state",0)
            localStorage.clear();
            setLoginToken(response.data.authToken);
            setUserModuleLogin(1);
            setUsernameLogin("");
            setPassword("");
            setError("");
            setLoaderState(false);
            // setPassword_CTX(response)
            localStorage.setItem("verify", "yes");
          } else if (response.status === StatusCodes.NOT_ACCEPTABLE) {
            setError("**Fields Cant Be empty**");
            setLoaderState(false);
          } else if (response.status === StatusCodes.BAD_REQUEST) {
            setError(`**${response.data.message}**`);
            setLoaderState(false);
          } else if (response.status === StatusCodes.FORBIDDEN) {
            navigate("/login");
          } else if (
            response.status === StatusCodes.OK &&
            response.data.userRole === "Admin"
          ) {
            // localStorage.setItem("state",0)
            setActiveModule(0);
            navigate("/licence");
          } else {
            throw response;
          }
        } catch (err) {
          // console.log("ERR =>",err);
          setLoaderState(false);
          if (err.data) {
            // setStepLicenseKey({ ...getStepLicenseKey, errorMsg: err.data.message, hasError: true });
          } else {
            if (err.errorMsg) {
              // setStepLicenseKey(e
              console.log(err.errorMsg);
            } else {
              console.error("UNKNOWN ERROR", err);
            }
          }
        }
      }
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleUsername = (e) => {
    setUsernameLogin(e.target.value);
  };

  useEffect(() => {
    console.log("beforeFd");
    // document.addEventListener('DOMContentLoaded',()=>{
    // console.log('fd')
    // document.getElementById('user-id').click()
    let timeout = setTimeout(() => {
      userFocus.current.click();
    }, 1000);
    // })
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {getUserModuleLogin === 0 ? (
        <Box
          className="mainBoxReg"
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: loginQuery ? "column" : "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FAFAFA",
            // gap: "1%",
            // paddingTop: "1%",
          }}
        >
          <Box
            className="mainBoxReg"
            sx={{
              width: "77%",
              // height: "77%",
              height: "558px",
              display: "flex",
              flexDirection: loginQuery ? "column" : "row-reverse",
              alignItems: "center",
              background: "white",
              borderRadius: "19px",
              overflow: "hidden",
              boxShadow: "0px 1px 22px 0px #e9e9e9",
              // gap: "1%",
              // paddingTop: "1%",
            }}
          >
            <Box
              className="respleft"
              sx={{
                width: loginQuery ? "100%" : "55%",
                height: "100%",
                // position: "absolute",
                overflow: "auto",
              }}
            >
              {/* <a
              rel="noopener noreferrer"
              target={"_blank"}
              href="https://soliterata.com/"
            >
              <img
                width="45"
                height="45"
                style={{
                  position: "absolute",
                  top: "6px",
                  left: "6px",
                  borderRadius: "6px",
                  zIndex: 2,
                }}
                src="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png"
                alt=""
                loading="lazy"
                srcSet="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png 150w, https://soliterata.com/wp-content/uploads/2022/06/final-300x300.png 300w, https://soliterata.com/wp-content/uploads/2022/06/final.png 500w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a> */}
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  display="flex"
                  width={loginQuery ? "93%" : "67%"}
                  height="56%"
                  alignItems="center"
                  flexDirection="column"
                  gap="12px"
                >
                  <Box
                    display="flex"
                    // flexDirection="column"
                    gap="12px"
                    alignItems="center"
                    // justifyContent="center"
                  >
                    {/* <a
            rel="noopener noreferrer"
            target={"_blank"}
            href="https://soliterata.com/"
          >
            <img
              width="45"
              height="45"
              style={{
                borderRadius: "6px",
                zIndex: 2,
              }}
              src="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png"
              alt=""
              loading="lazy"
              srcSet="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png 150w, https://soliterata.com/wp-content/uploads/2022/06/final-300x300.png 300w, https://soliterata.com/wp-content/uploads/2022/06/final.png 500w"
              sizes="(max-width: 150px) 100vw, 150px"
            />
          </a> */}
                    <Typography
                      variant="h3"
                      component="h2"
                      sx={{
                        fontWeight: "medium",
                        fontSize: "32px",
                        textAlign: "center",
                        color: text_col.col_primary,
                        // lineHeight: "21px",
                      }}
                    >
                      Welcome Back!
                    </Typography>
                  </Box>

                  <Box
                    className="errorSignIn"
                    sx={{
                      width: "52%",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="h2"
                      sx={{
                        color: "red",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: "2px",
                        fontSize: "10px",
                      }}
                    >
                      {error}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* <Typography
                    sx={{
                      fontSize: "10px",
                      lineHeight: loginQuery ? "1.1" : "0",
                      opacity: "0.8",
                    }}
                  >
                    Email/Username
                  </Typography> */}
                    <TextFieldTA
                      ref={userFocus}
                      autoComplete="new-password"
                      error={
                        usernameLogin === null
                          ? false
                          : usernameLogin === ""
                          ? true
                          : !validator.isEmail(usernameLogin)
                          ? true
                          : false
                      }
                      helperText={
                        <Typography sx={{ fontSize: "10px" }}>
                          {usernameLogin === null
                            ? ""
                            : usernameLogin === ""
                            ? "Email is required"
                            : !validator.isEmail(usernameLogin)
                            ? "Please enter a valid email"
                            : ""}
                        </Typography>
                      }
                      id="user-id"
                      // label="Email/Username"
                      placeholder="Email/Username"
                      variant="outlined"
                      sx={{ marginTop: "2%", width: "100%" }}
                      onChange={handleUsername}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* <Typography
                    sx={{
                      fontSize: "10px",
                      lineHeight: loginQuery ? "1.1" : "0",
                      opacity: "0.8",
                    }}
                  >
                    Password
                  </Typography> */}
                    <TextFieldTA
                      // ref={passRef}
                      // size="small"
                      // sx={{ width: "100%" }}
                      // fullwidth
                      // className="text"
                      required
                      helperText={
                        password === null
                          ? ""
                          : password === ""
                          ? "Password is required"
                          : password.length < 8
                          ? "Password should contain 8-characters"
                          : ""
                      }
                      error={
                        password === null
                          ? false
                          : password === ""
                          ? true
                          : password.length < 8
                          ? true
                          : false
                      }
                      onChange={handlePassword}
                      sx={{
                        marginTop: "2%",
                        width: "100%",
                        color: "red !important",
                      }}
                      variant="outlined"
                      // variant="outlined"
                      type={values.showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Password"
                    />
                  </Box>
                  <Box width="100%">
                    <ButtonMaterial
                      // disabled={
                      //   !validator.isEmail(usernameLogin) || password.length < 8
                      //     ? true
                      //     : getLoaderState
                      //     ? true
                      //     : false
                      // }
                      disabled={getLoaderState}
                      id="entert"
                      onClick={() => {
                        verifyInputs();
                      }}
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 1,
                        pr: 2,
                        width: "100%",
                        height: "45px",
                        // marginTop: "1%",
                      }}
                    >
                      LOGIN{" "}
                      {getLoaderState ? (
                        <CircularProgress
                          size="1.2em"
                          sx={{
                            position: "absolute",
                            right: "0",
                            mr: 2,
                            color: "white",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </ButtonMaterial>
                  </Box>
                  <Box sx={{ textAlign: "center", width: "100%" }}>
                    <Box
                      // className="respFPTxt"
                      onClick={routeChange}
                      sx={{
                        display: "flex",
                        width: "100%",
                        cursor: "pointer",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{ color: "#4D56BC" }}
                        variant="subtitle1"
                        component="h2"
                      >
                        Forgot Password?
                      </Typography>
                      {/* <Typography sx={{ color: '#4D56BC' }} variant="subtitle1" component="h2">
              Password?
            </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "13%",
                        gap: "1%",
                      }}
                    >
                      <Typography variant="subtitle1" component="h2">
                        Don't have an account?
                      </Typography>

                      <Typography
                        onClick={routeRegister}
                        sx={{ color: "#4D56BC", cursor: "pointer" }}
                        variant="subtitle1"
                        component="h2"
                      >
                        Register Now
                      </Typography>
                    </Box>
                    <Box></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              // className="respright"
              sx={{
                width: "45%",
                height: "100%",
                // position: "absolute",
                // marginLeft: "50%",
                backgroundColor: text_col.col_primary,
                textAlign: "center",
                display: loginQuery ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                // backgroundImage:'url(https://soliterata.com/wp-content/uploads/2024/01/STA-IMAGE-3.svg)',backgroundSize:'cover'
              }}
            >
              <a
                rel="noopener noreferrer"
                target={"_blank"}
                href="https://soliterata.com/"
              >
                <img
                  width="190"
                  height="190"
                  style={{
                    borderRadius: "6px",
                    zIndex: 2,
                    position: "absolute",
                    left: "12px",
                    top: "-60px",
                  }}
                  src="https://soliterata.com/wp-content/uploads/2024/01/Untitled-design14.svg"
                  alt=""
                  loading="lazy"
                  // srcSet="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png 150w, https://soliterata.com/wp-content/uploads/2022/06/final-300x300.png 300w, https://soliterata.com/wp-content/uploads/2022/06/final.png 500w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>
              <img
              alt='SoliteraTA'
                style={{ objectFit: "contain" }}
                src="https://soliterata.com/wp-content/uploads/2024/01/STA-IMAGE-5.22.png"
                width={"100%"}
                height="100%"
              />
              <Box width='100%' display='flex' justifyContent='center'   position="absolute"
                bottom="13px"
                >

              <Typography
                color="white"
                fontSize="12px"
                sx={{ cursor: "pointer" }}
              
                onClick={() =>
                  window.open("https://soliterata.com/", "__blank")
                }
              >
                www.soliterata.com
              </Typography>
              </Box>

              {/* <img
              src="https://soliterata.com/wp-content/uploads/2022/06/main-image.png"
              alt=""
              loading="lazy"
              srcSet="https://soliterata.com/wp-content/uploads/2022/06/main-image.png 590w, https://soliterata.com/wp-content/uploads/2022/06/main-image-295x300.png 295w"
              sizes="(max-width: 590px) 100vw, 590px"
              style={{ width: "80%", height: "80%" }}
            /> */}
            </Box>
          </Box>
        </Box>
      ) : (
        <ActiveUser getUserModuleLogin={getUserModuleLogin} />
      )}
    </>
  );
}

export function ActiveUser({ getUserModuleLogin }) {
  switch (getUserModuleLogin) {
    case 0:
      return <SignIn />;
    case 1:
      return <OtpStep />;
    default:
      return <SignIn />;
  }
}
